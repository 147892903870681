<template>
  <div class="d-flex justify-content-center align-center container-primo">
    <div class="d-flex flex-column">
      <span class="primo-title m-auto" v-text="'S’affilier à la FFME'" />
      <div class="d-flex" style="margin: 32px 0px;">
        <v-card
          v-for="(cart, index) in cardList"
          :key="index"
          elevation="3"
          min-width="300"
          height="300"
          class="mx-3 card-affiliation"
          @click.stop="goTo(cart)"
        >
          <div
            class="d-flex flex-column align-center justify-content-center"
            style="height: 100%;"
          >
            <div class="cart-title" v-text="cart.title" />
            <div class="cart-subtitle" v-text="cart.subtitle" />
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cardList: [
        {
          title: "ASSOCIATION",
          subtitle: "(club / section de club)",
          redirectionName: "primo_affiliation__inscription",
          params: "association",
        },
        {
          title: "ETABLISSEMENT AFFILIÉ",
          subtitle: "(entreprise privée type SARL)",
          redirectionName: "primo_affiliation__inscription",
          params: "EA",
        },
        {
          title: "MEMBRE ASSOCIÉ",
          subtitle: "",
          redirectionName: "primo_affiliation_MA",
          params: "MA",
        },
      ],
    };
  },
  methods: {
    goTo(item) {
      if (item.title !== "MEMBRE ASSOCIÉ") {
        return this.$router.push({
          name: item.redirectionName,
          params: { type: item.params },
        });
      }
      return this.$router.push({
        name: item.redirectionName,
      });
    },
  },
};
</script>

<style lang="scss">
.container-primo {
  width: 100%;
  height: calc(100vh - 150px);
}
.primo-title {
  width: 250px;
  height: 40px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -1px;
  color: #082b6e;
  flex: none;
  flex-grow: 0;
}
.cart-title {
  height: 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #000000;
  order: 0;
  flex-grow: 0;
  margin: 16px 0px;
}
.cart-subtitle {
  height: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 16px 0px;
}
.card-affiliation {
  cursor: pointer;
  border-radius: 22px !important;
}
.card-affiliation:hover {
  background: #e7effd !important;
}
.active-card {
  color: red !important;
  border: 2px solid red !important ;
}
</style>
