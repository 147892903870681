<template>
  <v-row style="display: block">
    <v-col class="text-center">
      <div class="text-center my-4" style="position: relative">
        <!-- <InputSelect
          :items="memberTypes"
          class="select-primo"
          v-model="selectedTypes"
        /> -->
        <span class="title"> S’AFFILIER À LA FFME </span>
      </div>
    </v-col>

    <v-stepper alt-labels v-model="step">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1"
          >Informations générales</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" step="2">Contact</v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step :complete="step > 3" step="3"
          >Documents à joindre</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 4" step="4"
          >Synthèse et validation</v-stepper-step
        >
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <step1 @onNext="step += 1" @onSetData="setDataAffiliation" />
        </v-stepper-content>

        <v-stepper-content step="2">
          <step2
            @onNext="step += 1"
            @onPrevious="step -= 1"
            @onSetData="setDataAffiliation"
          />
        </v-stepper-content>

        <v-stepper-content step="3">
          <step4
            @onNext="step += 1"
            @onPrevious="step -= 1"
            @onSetData="setDataAffiliation"
            :structure="affiliation.structure"
          />
        </v-stepper-content>

        <v-stepper-content step="4">
          <step5
            :selected-types="selectedTypes"
            @onSetData="setDataAffiliation"
            @onNext="step += 1"
            @onPrevious="step -= 1"
            @goToStep="
              (newStep) => {
                step = newStep;
              }
            "
            @submit="submit"
            :affiliation="affiliation"
          />
        </v-stepper-content>

        <v-stepper-content step="5">
          <step6 />
        </v-stepper-content>
        <v-stepper-content step="6">
          <step7 />
        </v-stepper-content>
        <v-stepper-content step="7">
          <step8 />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-row>
</template>

<script>
import step1 from "./PrimoAffiliation__step1.vue";
import step2 from "./PrimoAffiliation__step2.vue";
// import InputSelect from "@/components/utils/select.vue";

import step4 from "./PrimoAffiliation__step4.vue";
import step5 from "./PrimoAffiliation__step5.vue";
import step6 from "./PrimoAffiliation__step6.vue";
import step7 from "./PrimoAffiliation__step7.vue";
import step8 from "./PrimoAffiliation__step8.vue";

import { mapActions, mapGetters } from "vuex";
import { error_notification } from "@/plugins/notifications";
import { send_email } from "@/plugins/utils";
import {
  getStructuresFromZipcode,
} from "@/plugins/structureService";

export default {
  components: {
    step1,
    step2,
    /*step3,*/ step4,
    step5,
    step6,
    step7,
    step8,
    // InputSelect,
  },

  data: () => ({
    step: 1,
    affiliation: {},
    documentsType: [],
    departements: [],
    selectedTypes: "Association",
  }),
  watch: {
    selectedTypes() {
      if (this.selectedTypes !== "Membre associé") return;
      return this.$router.push({ name: "primo_affiliation_MA" });
    },
  },

  async created() {
    await this.setData();
  },

  computed: {
    ...mapGetters("saisons", ["getSaison"]),
  },

  methods: {
    ...mapActions("basics", ["getListTypesDocument"]),
    ...mapActions("structure", [
      "saveTempAffiliation",
      "getStructurePresident",
      "getStructureByID",
    ]),

    setDataAffiliation(data) {
      this.affiliation = { ...this.affiliation, ...data };
    },

    goToStep(step) {
      this.step = step;
    },

    capitalizeFirstLetter(string) {
      let str = string.split(" ");
      for (var i = 0, x = str.length; i < x; i++) {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1);
      }
      if (str.length > 1) {
        return str.join(" ");
      }
      return str[0];
    },

    async submit() {
      this.step = 5;
      let payload = {
        STR_NomStructure: this.affiliation.structure.NomStructure,
        STR_InfoPresentation: this.affiliation.structure.INFO_Presentation,
        JUR_Siret: this.affiliation.structure.JUR_Siret,
        ID_TypeStructure: this.$route.params.type === "association" ? 1 : 3, // association / établissement affilié
        JUR_AgrementJeunesseSports:
          this.affiliation.structure.JUR_AgrementJeunesseSports,
        STR_Affiliation: JSON.stringify(this.affiliation.affiliation),
        MKP_Tarifs: JSON.stringify(this.affiliation.tarifs),
        ADR_Adresse: JSON.stringify(this.affiliation.adresse),
        UTI_Utilisateur: JSON.stringify(this.affiliation.utilisateurs),
        ACTI_Activites: JSON.stringify(this.affiliation.activites),
        ID_Premiere_Saison: this.affiliation.affiliation.ID_Premiere_Saison.id,
        Z_DateCreationDemande: this.$moment().toISOString(),
        EST_SectionAssociation: this.affiliation.structure.EST_SectionAssociation,
        ID_Departement: this.affiliation.adresse.Departement.id,
        NomStructureComplement:
          this.affiliation.structure.NomStructureComplement,
        MKP_Rib: JSON.stringify({
          IBAN: this.affiliation.documents?.IBAN,
          BIC: this.affiliation.documents?.BIC,
        }),
      };
      let NomStructureDocUpload = this.affiliation.structure.NomStructure;
      try {
        payload.DOC_DocumentsTemp = {};
        payload.DOC_DocumentsTemp.data = [];
        console.log("this.affiliation :>> ", this.affiliation);
        let file_DOC_LEGAL = null;
        if (this.affiliation?.representant) {
          file_DOC_LEGAL = await this.uploadFile(
            this.affiliation.representant,
            NomStructureDocUpload
          );
        }

        let file_DOC_Status = await this.uploadFile(
          this.affiliation.documents.DOC_Status,
          NomStructureDocUpload
        );
        let file_DOC_RIB = await this.uploadFile(
          this.affiliation.documents.DOC_RIB,
          NomStructureDocUpload
        );
        let file_DOC_SEPA = await this.uploadFile(
          this.affiliation.documents.DOC_SEPA,
          NomStructureDocUpload
        );

        payload.DOC_DocumentsTemp.data.push(
          this.getDocumentPaylod(
            file_DOC_Status,
            "status",
            NomStructureDocUpload
          ),
          this.getDocumentPaylod(
            file_DOC_RIB,
            "rib",
            NomStructureDocUpload
          ),
          this.getDocumentPaylod(
            file_DOC_SEPA,
            "mandat_sepa",
            NomStructureDocUpload
          ),
        );
        if (file_DOC_LEGAL) {
          payload.DOC_DocumentsTemp.data.push(
            this.getDocumentPaylod(
              file_DOC_LEGAL,
              "mandat_représentant_légal",
              NomStructureDocUpload
            )
          );
        }
        // association
        if (
            payload.ID_TypeStructure === 1 && !payload.EST_SectionAssociation
        ) {
          let file_DOC_JO = await this.uploadFile(
            this.affiliation.documents.DOC_JO,
            NomStructureDocUpload
          );
          let file_DOC_RecepissePrefectoral = await this.uploadFile(
            this.affiliation.documents.DOC_RecepissePrefectoral,
            NomStructureDocUpload
          );

          payload.DOC_DocumentsTemp.data.push(
            this.getDocumentPaylod(
              file_DOC_JO,
              "parution_jo",
              NomStructureDocUpload
            ),
            this.getDocumentPaylod(
              file_DOC_RecepissePrefectoral,
              "recepisse_prefectoral_declaration",
              NomStructureDocUpload
            )
          );
        }
        // section d'association
        else if (
            payload.ID_TypeStructure === 1 && payload.EST_SectionAssociation
        ) {
          let file_DOC_JO = await this.uploadFile(
            this.affiliation.documents.DOC_JO,
            NomStructureDocUpload
          );
          let file_DOC_RecepissePrefectoral = await this.uploadFile(
            this.affiliation.documents.DOC_RecepissePrefectoral,
            NomStructureDocUpload
          );
          let file_DOC_Reglement = await this.uploadFile(
            this.affiliation.documents.DOC_Reglement,
            NomStructureDocUpload
          );

          payload.DOC_DocumentsTemp.data.push(
            this.getDocumentPaylod(
              file_DOC_JO,
              "parution_jo",
              NomStructureDocUpload
            ),
            this.getDocumentPaylod(
              file_DOC_RecepissePrefectoral,
              "recepisse_prefectoral_declaration",
              NomStructureDocUpload
            ),
            this.getDocumentPaylod(
              file_DOC_Reglement,
              "reglement_interieur",
              NomStructureDocUpload
            )
          );
        }
        // établissement affilié
        else if (
            payload.ID_TypeStructure === 3
        ) {
          let file_DOC_Diplomes = await this.uploadFile(
            this.affiliation.documents.DOC_Diplomes,
            NomStructureDocUpload
          );
          let file_DOC_Cartes = await this.uploadFile(
            this.affiliation.documents.DOC_Cartes,
            NomStructureDocUpload
          );
          let file_DOC_Attestation = await this.uploadFile(
            this.affiliation.documents.DOC_Attestation,
            NomStructureDocUpload
          );

          payload.DOC_DocumentsTemp.data.push(
            this.getDocumentPaylod(
              file_DOC_Diplomes,
              "diplome_encadrant",
              NomStructureDocUpload
            ),
            this.getDocumentPaylod(
              file_DOC_Cartes,
              "copie_cartes_professionnelles",
              NomStructureDocUpload
            ),
            this.getDocumentPaylod(
              file_DOC_Attestation,
              "attestation_assurance",
              NomStructureDocUpload
            )
          );
        }
        //console.log(payload)

        // console.log("bedore save", payload)
        let responseAffi = await this.saveTempAffiliation({
          affiliation: payload,
        });
        console.log("response-affi", responseAffi);

        const season = this.getSaison(parseInt(this.affiliation.affiliation.ID_Premiere_Saison, 10));
        
        let idAffi = responseAffi.insert_STR_AffiliationTemp.returning[0].id;
        console.log("--ENVOI AU REPRESENTANT DU  NOUVEAU CLUB---");
        // Envoyer un email a la personne qui vient de remplir le formulaire

        let payloadEmailConfirmation = {
          User: "0c3d061f-d338-4676-856e-62d18924ea62",
          Structure: payload.STR_NomStructure,
          Context: "confirmPrimoAffiliationRequest",
          Email: this.affiliation.utilisateurs.correspondant.CT_Email,
          Comment: idAffi.toString(),
          CreditAmount: (this.affiliation.tarifStructure / 100).toString(),
          SeasonId: season.id.toString(),
          NextSeasonText: season.ID_EtapeSaison === 3 ? ` à partir d'octobre ${new Date().getFullYear()}` : "",
        };
        console.log("payloadEmailConfirmation", payloadEmailConfirmation);
        const responseConfirmation = await send_email(
          payloadEmailConfirmation,
          true
        );
        console.log(
          "--FIN ENVOI AU REPRESENTANT DU  NOUVEAU CLUB---",
          responseConfirmation
        );

        //Send email to SIEGE NATIONAL for information NEW STRUCTURE
        console.log("--ENVOI AU REPRESENTANT DU SN---");
        let linkAffiliation =
          process.env.VUE_APP_FRONT_URL +
          "gestion-des-affiliations/primo-affiliations";
        let payloadEmailConfirmationSN = {
          User: "0c3d061f-d338-4676-856e-62d18924ea62",
          Structure: payload.STR_NomStructure,
          Context: "avisPrimoAffiliationRequest",
          Email: "licence@ffme.fr",
          Comment: idAffi.toString(),
          Link: linkAffiliation,
        };
        console.log("payloadEmailConfirmationSN", payloadEmailConfirmationSN);
        const responseConfirmationSN = await send_email(
          payloadEmailConfirmationSN,
          true
        );
        console.log(
          "--FIN ENVOI AU REPRESENTANT DU SN---",
          responseConfirmationSN
        );

        // // get the structure ct corresping to the departement
        let ct = this.affiliation.adresse.Departement.STR_Structure.find(
          (str) => str.ID_TypeStructure == 4
        );

        const parentStructure = await getStructuresFromZipcode({
          zipcode: this.affiliation.adresse.CodePostal,
        })

        // Ligue or CT ID
        const firstStructureId = parentStructure[0];
        let structure;

        if (firstStructureId !== 1318) {
          structure = await this.getStructureByID(firstStructureId);
        }

        // let linkAffiliation =
        //   process.env.VUE_APP_FRONT_URL + 'primo-affiliations/' + idAffi;
        console.log("--ENVOI this.affiliation---", this.affiliation);

        console.log("--ENVOI AU REPRESENTANT CT---", structure);
        // // Envoyer un email au representant du ct si il existe / INFO_Email
        if (structure && structure.INFO_Email) {
          let payloadEmailConfirmationCT = {
            Email: structure.INFO_Email,
            Structure: payload.STR_NomStructure,
            Context: "avisPrimoAffiliationRequest",
            Link: linkAffiliation,
            User: "0c3d061f-d338-4676-856e-62d18924ea62",
            Comment: idAffi.toString(),
          };
          console.log(
            "--ENVOI payloadEmailConfirmationCT---",
            payloadEmailConfirmationCT
          );

          const responseCT = await send_email(payloadEmailConfirmationCT, true);
        }
        // Envoyer un email au president du ct
        // let president = await this.getStructurePresident({ID_Structure: ct.ID_Structure})
        // if(president.length && president[0].UTI_Utilisateur.CT_Email) {
        //   let payloadCTPresident  = {
        //       "Email": president[0].UTI_Utilisateur.CT_Email,
        //       "Structure": payload.STR_NomStructure,
        //       "Context": "avisPrimoAffiliationRequest",
        //       "Link": linkAffiliation
        //   }
        //   const responseCTPresident = await send_email(payloadCTPresident, true)
        // }
        console.log("--ENVOI AU PRESIDENT CT---");
        console.log("affiliation temp crearted");

        this.step = 6;
      } catch (e) {
        console.log(e);
        error_notification("Oups, une erreur est survenue...");
        this.step = 7;
      }

      // console.log(payload);
    },

    getDocumentPaylod(file, slugType, structure) {
      console.log(
        "structure -----",
        `${structure}_${slugType}_${file.filename}`
      );
      return {
        CheminDocument: file.url,
        NomDocument: `${this.$moment().toISOString()}_${structure}_${slugType}_${
          file.filename
        }`,
        DateFinValiditeDocument: file.date,
        ID_DocumentType: this.documentsType.find(
          (doc) => doc.SlugTypeDocument === slugType
        )?.id,
      };
    },

    async uploadFile(file, structure) {
      console.log(file.file);
      const hash =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);

      let payload = {
        image: file.fileDataURL,
        key: `${structure}_${hash}_${file.file.name}`,
        config: {
          S3_BUCKET: process.env.S3_BUCKET,
        },
      };
      console.log("file payload", payload);
      return await this.axios
        .post(process.env.VUE_APP_FFME_BACK_URL + "/api/media-upload", payload)
        .then((response) => {
          if (
            response.data &&
            response.data.location &&
            response.status &&
            response.status === 200
          ) {
            let freshFile = {
              url: response.data.location,
              filename: file.file.name,
              date: file.validationDate,
            };

            return freshFile;
          } else {
            error_notification("Upload unsuccessful");
          }
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
    setSelectedMemberType() {
      console.log("this.$route.params. :>> ", this.$route.params.type);
      if (this.$route.params.type === "association") {
        return (this.selectedTypes = "Association");
      }
      return (this.selectedTypes = "Etablissement affilié");
    },
    async setData() {
      this.setSelectedMemberType();
      let type = await this.getListTypesDocument();
      this.documentsType = type.filter(
        (doc) =>
          doc.LIST_DocumentCategory?.SlugCategorieDocument === "documents_club"
      );
      return;
    },
  },
};
</script>

<style scoped>
.title {
  height: 32px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 32px !important;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #000000;
}
.v-stepper__label {
  text-align: center !important;
  font-size: 0.9rem !important;
}
.select-primo {
  position: absolute;
  left: 0px;
  top: -10px;
}
</style>
